@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

#header {
    position: sticky;
    top: 0;
}

#headerOverlay > div,#headerOverlay {
    background-color: inherit;
    background-blend-mode: difference;
}

body {
    overflow-x: hidden;
}

* {
    font-family: 'Inter';
}

.grayscale-image {
    filter: grayscale(100%);
}

.noScroll {
    /*overflow-y: hidden;*/
}

#planInfoScrollText {
    font-variant-numeric: tabular-nums;
}


/* zendesk widget */
#launcher {
    z-index: 1!important;
}